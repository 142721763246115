<template>
<div>
  <v-list style="margin: 0 auto; width: 800px;">
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="navigateTo('/videos')">Videos</v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="navigateTo('/staff')">staff</v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="navigateTo('/questionSetEditor')">Edit</v-btn>
          <v-list>
            <v-list-item v-for="questionSet in questionSetItems" :key="questionSet.value">
              <v-btn @click="navigateTo('/questions/'+questionSet.value)">{{questionSet.value}}</v-btn>
            </v-list-item>
          </v-list>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="navigateTo({name:'Events',params:{language:$store.getters.language}})">Events</v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="navigateTo({name:'PXP'})">PXP</v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="navigateTo({name:'PatientAssessment'})">Patient Assessment</v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="navigateTo({name:'Dashboard'})">Dashboard</v-btn>
        </v-list-item-content>
      </v-list-item>
  </v-list>
</div>
</template>

<script>
import cache from '@/plugins/cache.js'
export default {
  name: "Home",
  methods: {
    navigateTo: function(path){
      try{
        this.$router.push(path)
      }catch(err){
        console.log('Navigation Error',err)
      }
    }
  },
  computed: {
    questionSetItems: function(){
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache') ? JSON.parse(storage.getItem('survey_editor_cache')) : {}
        let output = [];

        if(cache){
            for(let key in cache){
                output.push({
                    text: key,
                    value: key
                })
            }
        }

        

        return output
    }
  }
};
</script>
